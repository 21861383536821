import React, { useEffect, useState, useMemo } from 'react';
import api from "../../api/endpoints/events";
import { useParams } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import pharmaLogo from '../../assets/images/bcf_pharma_logo.png';

const eventBTEL = ["6TH ANNUAL BTEL SUMMIT & EXHIBITION"];

function Header() {
    const { id } = useParams();
    const [conference, setConference] = useState({});
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const fetchConferenceData = async () => {
            try {
                const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
                setConference(data);
            } catch (error) {
                console.error('Error fetching conference data:', error);
            }
        };

        if (id) {
            fetchConferenceData();
        }
    }, [id]);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const logoSrc = useMemo(() => {
        return eventBTEL.includes(conference.eventName) ? pharmaLogo : logo;
    }, [conference.eventName]);

    const liStyle = useMemo(() => {
        return eventBTEL.includes(conference.eventName)
            ? { color: '#D85121', marginRight: '10px' }  // Стиль для BTEL
            : { color: '#FFFFFF', marginRight: '10px' }; // Белый цвет для остальных
    }, [conference.eventName]);

    const logoStyle = useMemo(() => {
        return eventBTEL.includes(conference.eventName)
            ? { height: '200px', width: 'auto', marginRight: '20px' }  // Размеры для BTEL
            : { height: '50px', width: 'auto', marginRight: '10px' };  // Меньший логотип для остальных
    }, [conference.eventName]);

    const headerStyle = useMemo(() => {
        if (eventBTEL.includes(conference.eventName)) {
            return {
                paddingTop: '20px',
                backgroundColor: isScrolled ? '#E6E7E8' : 'initial',
                paddingBottom: '10px'
            };
        } else {
            return {
                paddingTop: '25px',
                backgroundColor: isScrolled ? '#00005A' : 'initial', // Начальный цвет 'initial', синий цвет при прокрутке
                paddingBottom: '10px'
            };
        }
    }, [conference.eventName, isScrolled]);

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`} style={headerStyle}>
            <div className="desktop-header" style={{ paddingTop: '10px', marginTop: '10px', height: '40px' }}>
                <a href='/'>
                    <img
                        src={logoSrc}
                        alt="Logo"
                        style={logoStyle}
                    />
                </a>
                <nav>
                    <ul style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <li style={liStyle}><a href="/about" style={liStyle}>ABOUT US</a></li>
                        <li style={liStyle}><a href='/sponsorship' style={liStyle}>SPONSORSHIP</a></li>
                        <li style={liStyle}><a href='/conferences' style={liStyle}>B2B CONFERENCES</a></li>
                        <li style={liStyle}><a href="/faq" style={liStyle}>FAQs</a></li>
                        <li style={liStyle}><a href="/gallery" style={liStyle}>GALLERY</a></li>
                        <li style={liStyle}><a href='/events' style={liStyle}>PAST EVENTS</a></li>
                        <li style={liStyle}><a href='/contacts' style={liStyle}>CONTACT US</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
