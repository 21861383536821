import React, { useEffect } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import Breadcrumbs from "../../components/Breadcrumbs";

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderConferences />
      <main>
        <section>
          <div className="container">
            <div class="breadcrumbs-about breadcrumbs-add">
              <ul>
                <li className="andThisStyle title-breadcrumbs">MAIN</li>
                <li className="thisStyle description-breadcrumbs">
                  TERMS AND CONDITIONS
                </li>
              </ul>
              <Breadcrumbs />
            </div>
            <h2 className="title-conferences title-contact-us event-contact-us">
              Terms and conditions
            </h2>
            <div className="line-conferences line-contact-us line-event-us">
              <span className="line-about"></span>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h3 className="title-terms">
              Attendance at any summit, conference, training organized by BCF
              (Business Conference Facilitation), is subject to our Terms and
              Conditions.
            </h3>
            <p className="text-terms">
              By registering for any of our events by e-mail or online the
              client accepts and agrees to be bound by these Terms and
              Conditions.
            </p>
            <h3 className="title-terms">
              Registration Confirmation and Payment Policy:
            </h3>
            <p className="text-terms">
              We will confirm your participation after receiving the signed registration form.
              The client will receive the invoice within 24hrs of sending the signed form.
              We require the full payment of the registration fee within 10 working days of the invoice issue date.
            </p>
            <p className="text-terms">
              PARTICIPATION FEES: VIRTUAL EVENT – fees are inclusive of the complete summit materials, online post-event
              documentation/presentation package, list of participants, video recordings, and certificate of
              participation (upon to request).
              OFFLINE EVENTS – fees are inclusive of the 2-day summit, materials, online post-event
              documentation/presentation package, list of participants, video recordings, certificate of participation,
              lunches, snacks, refreshments, and business dinner.

            </p>
            <p className="text-terms">
              TRAVEL AND ACCOMMODATION: Hotel accommodation and travel expenses are not included in the fee. Special
              rates for the event venue accommodation will be sent upon availability. STREAMING: The online streaming
              link will be announced and sent to the delegates within a reasonable period, not less than 2 days before
              the summit start date.
            </p>
            <p className="text-terms">
              POST-EVENT DOCUMENTATION: Presentations and other materials will be sent to the attendees within 10
              working days after the event. The presentation content is subject to the Speaker’s companies approval for
              distribution.
            </p>
            <p className="text-terms">
              The venue details will be sent two to three weeks before the start of the event. In case, you would like
              to get accommodation details or group booking, please contact:{" "}
              <a href="mailto:general@bcf-events.com" className="email-address">
                general@bcf-events.com
              </a>.
              <br/><br/>
              By signing this registration form, you authorize the sharing of your email address with fellow conference participants.
            </p>
            <h3 className="title-terms">Cancellations and Substitutions:</h3>
            <p className="text-terms">
              A delegate may be substituted up to 2 days before the event. 30 days prior the event, or in case of non-attendance – no free cancellation applicable *31-90 days prior the event – 50% participation fee can be refunded * more than 91 days prior the event – 75% participation fee can be refunded ****Refunds will be made after the event. Substitutes are accepted up to 2 weeks before the event. The refund can be issued in the form of a credit note with a validity of two years. A credit note is a voucher that the company receives and can use for any conference organized by BCF during the credit’s valid period.
              Cancellation should take place in written form. Verbal cancellations are not legally binding. 100% refund is possible in Force Mature scenarios, such as: sickness of the delegate, forbidden actions of the government authorities, civil strife, epidemic, embargo, earthquakes, conflagrations, inundations and other natural disasters, denial of the visa. All of the above cases must be proved by legal documentation. The refund can be issued in the form of a credit note with a validity of two years. A credit note is a voucher that the company receives and can use for any conference organized by BCF during the credit’s valid period.
            </p>
            <p className="text-terms">
              Cancellation should take place in written form. Verbal
              cancellations are not legally binding. 100% refund is possible in
              Force Mature scenarios, such as: sickness of the delegate,
              forbidden actions of the government authorities, civil strife,
              epidemic, embargo, earthquakes, conflagrations, inundations and
              other natural disasters, denial of the visa. All of the above
              cases must be proved by legal documentation.
            </p>
            <h3 className="title-terms">
              Force Majuro and Permanent Cancellation of Event:
            </h3>
            <p className="text-terms">
              BCF reserves the right to change event dates, sites or location,
              omit event features, or merge the event with another event as it
              deems necessary without penalty and in such situations no refunds,
              part refunds or alternative offers shall be made. In the event
              that BCF permanently cancels the event for any reason whatever,
              (including, but not limited to any force majeure occurrence) and
              provided that the event is not postponed to a later date nor is it
              merged with another event, the Client shall receive a credit note
              for the amount that the Client has paid to such permanently
              canceled event. No refunds, part refunds or alternative offers
              shall be made.
            </p>
            <h3 className="title-terms">Copyright:</h3>
            <p className="text-terms last-terms">
              All intellectual property rights in all materials produced or
              distributed by BCF in connection with this event are expressly
              reserved and any unauthorized duplication, publication or
              distribution is prohibited.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Terms;
